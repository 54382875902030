import React, { useState } from 'react'
import { ThemeProvider } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    color: {
      primary: string
      secondary: string
      background: string
      text: string
      block: string
    }
  }
}

export interface StyledTheme {
  theme: ThemeType
}

export type ThemeType = {
  color: {
    primary: string
    secondary: string
    background: string
    text: string
    block: string
  }
}

export const themes = {
  light: {
    color: {
      primary: '#2eaeec',
      secondary: '#dabe5e',
      background: 'white',
      text: 'black',
      block: 'white',
    },
  },
  dark: {
    color: {
      primary: '#2eaeec',
      secondary: '#dabe5e',
      background: '#111111',
      text: '#fafafa',
      block: '#292929',
    },
  },
  blueNight: {
    color: {
      primary: '#2eaeec',
      secondary: '#dabe5e',
      background: '#030b17',
      text: '#fafafa',
      block: '#081B33',
    },
  },
}

export const ThemeContext = React.createContext({
  currentTheme: 'light',
  theme: themes.light,
  setTheme: (v: 'dark' | 'light') => {},
})

const ThemeContextProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = useState<'dark' | 'light'>(
    () =>
      (typeof localStorage !== 'undefined' &&
        (localStorage.getItem('theme') as 'dark' | 'light')) ||
      'light'
  )

  return (
    <ThemeContext.Provider
      value={{
        currentTheme: theme,
        theme: themes[theme],
        setTheme: (v: 'dark' | 'light') => (
          setTheme(v), localStorage.setItem('theme', v)
        ),
      }}
    >
      <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  )
}

export default ThemeContextProvider
